import React, { createContext, useContext, useState } from 'react'

const initialState = { bannerHeight: 0 }
const BannerHeightContext = createContext(initialState)

export const BannerHeightProvider = ({ children }) => {
    const [bannerHeight, setBannerHeight] = useState()

    return (
        <BannerHeightContext.Provider value={{ bannerHeight, setBannerHeight }}>
            {children}
        </BannerHeightContext.Provider>
    )
}

export const useBannerHeight = () => useContext(BannerHeightContext)
